const headingA = document.querySelectorAll('.section-title,' +
	'.promotion-item__title,' +
	'.cta__title,' +
	'.fighter-item__name,' +
	'.event-item__fighters,' +
	'.page-banner__title,' +
	'.landing-list-item__title,' +
	'.fighter-heading__name,' +
	'.banner-title');

for (let i = 0; i < headingA.length; i++) {
	headingA[i].innerHTML = '<span>'+ headingA[i].innerHTML +'</span>';
	let child = headingA[i].children;
	console.log(child);
	new Waypoint({
		element: headingA[i],
		handler: function() {
			for (let c = 0; c < child.length; c++) {
				anime({
					translateY: ['100%', '0'],
					targets: child[c],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			}
		},
		offset: 'bottom-in-view'
	});
}

const fadeUp = document.querySelectorAll('.fadeUp,' +
	'.cta__desc,' +
	'.events-filter-item,' +
	'.news-item,' +
	'.event-item,' +
	'.co-main-event,' +
	'.writer-item,' +
	'.main-event__heading,' +
	'.fighter-item,' +
	'.stat-item,' +
	'.landing-list-item__desc,' +
	'.record-table tr,' +
	'.banner-event-info-value,' +
	'.section-desc,' +
	'.article-data,' +
	'.article,' +
	'.article-content > *,' +
	'.share-article,' +
	'.article-meta,' +
	'.article-title');


for (let i = 0; i < fadeUp.length; i++) {
	new Waypoint({
		element: fadeUp[i],
		handler: function() {
			anime({
				opacity: 1,
				targets: this.element,
				translateY: ['30px', '0'],
				duration: 500,
				easing: 'easeInOutQuad',
			});
			this.destroy();
		},
		offset: '75%'
	});
}

const bannereventinfovalue = document.querySelectorAll('.fadeUp,' +
	'' +
	'.banner-event-info-value');

for (let i = 0; i < bannereventinfovalue.length; i++) {
	new Waypoint({
		element: bannereventinfovalue[i],
		handler: function() {
			anime({
				opacity: 1,
				targets: this.element,
				translateY: ['30px', '0'],
				duration: 500,
				easing: 'easeInOutQuad',
			});
			this.destroy();
		},
		offset: 'bottom-in-view'
	});
}

const childCascadeFadeUp = document.querySelectorAll('.childCascadeFadeUp,' +
	'.promotion-item__data,' +
	'.promotion-item__desc,' +
	'.promotion-item__soc,' +
	'.cta__desc,' +
	'.banner-desc,' +
	'.main-event__info,' +
	'.media-grid,' +
	'.fighter-heading__grid,' +
	'.co-main-event__info,' +
	'.share-grid,' +
	'.faq-list');

for (let i = 0; i < childCascadeFadeUp.length; i++) {

	let child = childCascadeFadeUp[i].children;
	new Waypoint({
		element: childCascadeFadeUp[i],
		handler: function() {
			for (let c = 0; c < child.length; c++) {
				anime({
					opacity: 1,
					targets: child[c],
					translateY: ['30px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
					delay: 100 * c
				});
				this.destroy();
			}
		},
		offset: '70%'
	});
}

const newsfilter = document.querySelectorAll('.childCascadeFadeUp,' +
	'.news-filter');

for (let i = 0; i < newsfilter.length; i++) {

	let child = newsfilter[i].children;
	new Waypoint({
		element: newsfilter[i],
		handler: function() {
			for (let c = 0; c < child.length; c++) {
				anime({
					opacity: 1,
					targets: child[c],
					translateY: ['30px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
					delay: 100 * c
				});
				this.destroy();
			}
		},
		offset: 'bottom-in-view'
	});
}

const scale = document.querySelectorAll('.promotion-item__pct img,' +
	'.landing-list-item__pct img,' +
	'.contacts-banner img,' +
	'.page-banner img');

for (let i = 0; i < scale.length; i++) {
	new Waypoint({
		element: scale[i],
		handler: function() {
			anime({
				opacity: 1,
				targets: this.element,
				scale: ['1', '1.1'],
				duration: 500,
				easing: 'easeInOutQuad',
			});
			this.destroy();
		},
		offset: '70%'
	});
}


const fadeDown = document.querySelectorAll('.fadeDown');

for (let i = 0; i < fadeDown.length; i++) {
	new Waypoint({
		element: fadeDown[i],
		handler: function() {
			anime({
				opacity: 1,
				targets: this.element,
				translateY: ['-50px', '0'],
				duration: 500,
				easing: 'easeInOutQuad',
			});
			this.destroy();
		},
		offset: 'bottom-in-view'
	});
}

const fadeToLeft = document.querySelectorAll('.fadeToLeft');

for (let i = 0; i < fadeToLeft.length; i++) {
	new Waypoint({
		element: fadeToLeft[i],
		handler: function() {
			anime({
				opacity: 1,
				targets: this.element,
				translateX: ['100px', '0'],
				duration: 500,
				easing: 'easeInOutQuad',
			});
			this.destroy();
		},
		offset: 'bottom-in-view'
	});
}

const fadeToRight = document.querySelectorAll('.fadeToRight');

for (let i = 0; i < fadeToRight.length; i++) {
	new Waypoint({
		element: fadeToRight[i],
		handler: function() {
			anime({
				opacity: 1,
				targets: this.element,
				translateX: ['-100px', '0'],
				duration: 500,
				easing: 'easeInOutQuad',
			});
			this.destroy();
		},
		offset: 'bottom-in-view'
	});
}

const fadeScale = document.querySelectorAll('.fadeScale');

for (let i = 0; i < fadeScale.length; i++) {
	new Waypoint({
		element: fadeScale[i],
		handler: function() {
			anime({
				opacity: 1,
				targets: this.element,
				scale: ['0.9', '1'],
				duration: 1000,
				easing: 'easeInOutQuad',
			});
			this.destroy();
		},
		offset: 'bottom-in-view'
	});
}

const childCascadeFade = document.querySelectorAll('.childCascadeFade');

for (let i = 0; i < childCascadeFade.length; i++) {
	let child = childCascadeFade[i].children;
	new Waypoint({
		element: childCascadeFade[i],
		handler: function() {
			for (let c = 0; c < child.length; c++) {
				anime({
					opacity: 1,
					targets: child[c],
					duration: 500,
					easing: 'easeInOutQuad',
					delay: 200 * c
				});
				this.destroy();
			}
		},
		offset: childCascadeFadeUp[i].offsetHeight * 2
	});
}
