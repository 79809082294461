let select = $( 'select' );
let selectricHeight = 0;
var mediaCarousel = false;
var mouseX = 0;
var mouseY = 0;
var mouseFollowSpeed = 0.1;
function handleMouseMove(event) {
	event = event || window.event;
	mouseX = event.pageX;
	mouseY = event.pageY;
}
function updateElementPosition() {
	var currentX = parseFloat($('.follow-cursor').css('left'));
	var currentY = parseFloat($('.follow-cursor').css('top'));
	var dx = mouseX - currentX;
	var dy = mouseY - currentY;
	var newX = currentX + dx * mouseFollowSpeed;
	var newY = currentY + dy * mouseFollowSpeed;
	$('.follow-cursor').css({
		'left': newX + 'px',
		'top': newY + 'px'
	});
	requestAnimationFrame(updateElementPosition);
}

function allAnimations() {
	const fadeUps = document.querySelectorAll('.fadeUp,' +
		'.writer-item,' +
		'.news-item,' +
		'.fighter-item,' +
		'.event-item');

	for (let i = 0; i < fadeUps.length; i++) {
		new Waypoint({
			element: fadeUps[i],
			handler: function() {
				anime({
					opacity: [0, 1],
					targets: this.element,
					translateY: ['30px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: '75%'
		});
	}

	const newsfilter = document.querySelectorAll('.childCascadeFadeUp,' +
		'.writer-item__foot .news-filter');

	for (let i = 0; i < newsfilter.length; i++) {

		let child = newsfilter[i].children;
		new Waypoint({
			element: newsfilter[i],
			handler: function() {
				for (let c = 0; c < child.length; c++) {
					anime({
						opacity: 1,
						targets: child[c],
						translateY: ['30px', '0'],
						duration: 500,
						easing: 'easeInOutQuad',
						delay: 100 * c
					});
					this.destroy();
				}
			},
			offset: 'bottom-in-view'
		});
	}
}


$( document ).ready( function() {
	const headingA = document.querySelectorAll('.section-title,' +
		'.promotion-item__title,' +
		'.cta__title,' +
		'.fighter-item__name,' +
		'.event-item__fighters,' +
		'.page-banner__title,' +
		'.landing-list-item__title,' +
		'.fighter-heading__name,' +
		'.banner-title');
	
	for (let i = 0; i < headingA.length; i++) {
		headingA[i].innerHTML = '<span>'+ headingA[i].innerHTML +'</span>';
		let child = headingA[i].children;
		console.log(child);
		new Waypoint({
			element: headingA[i],
			handler: function() {
				for (let c = 0; c < child.length; c++) {
					anime({
						translateY: ['100%', '0'],
						targets: child[c],
						duration: 500,
						easing: 'easeInOutQuad',
					});
					this.destroy();
				}
			},
			offset: 'bottom-in-view'
		});
	}
	
	const fadeUp = document.querySelectorAll('.fadeUp,' +
		'.cta__desc,' +
		'.events-filter-item,' +
		'.news-item,' +
		'.event-item,' +
		'.co-main-event,' +
		'.writer-item,' +
		'.main-event__heading,' +
		'.fighter-item,' +
		'.stat-item,' +
		'.landing-list-item__desc,' +
		'.record-table tr,' +
		'.banner-event-info-value,' +
		'.section-desc,' +
		'.article-data,' +
		'.article,' +
		'.article-content > *,' +
		'.share-article,' +
		'.article-meta,' +
		'.article-title');
	
	
	for (let i = 0; i < fadeUp.length; i++) {
		new Waypoint({
			element: fadeUp[i],
			handler: function() {
				anime({
					opacity: 1,
					targets: this.element,
					translateY: ['30px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: '75%'
		});
	}
	
	const bannereventinfovalue = document.querySelectorAll('.fadeUp,' +
		'' +
		'.banner-event-info-value');
	
	for (let i = 0; i < bannereventinfovalue.length; i++) {
		new Waypoint({
			element: bannereventinfovalue[i],
			handler: function() {
				anime({
					opacity: 1,
					targets: this.element,
					translateY: ['30px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: 'bottom-in-view'
		});
	}
	
	const childCascadeFadeUp = document.querySelectorAll('.childCascadeFadeUp,' +
		'.promotion-item__data,' +
		'.promotion-item__desc,' +
		'.promotion-item__soc,' +
		'.cta__desc,' +
		'.banner-desc,' +
		'.main-event__info,' +
		'.media-grid,' +
		'.fighter-heading__grid,' +
		'.co-main-event__info,' +
		'.share-grid,' +
		'.faq-list');
	
	for (let i = 0; i < childCascadeFadeUp.length; i++) {
	
		let child = childCascadeFadeUp[i].children;
		new Waypoint({
			element: childCascadeFadeUp[i],
			handler: function() {
				for (let c = 0; c < child.length; c++) {
					anime({
						opacity: 1,
						targets: child[c],
						translateY: ['30px', '0'],
						duration: 500,
						easing: 'easeInOutQuad',
						delay: 100 * c
					});
					this.destroy();
				}
			},
			offset: '70%'
		});
	}
	
	const newsfilter = document.querySelectorAll('.childCascadeFadeUp,' +
		'.news-filter');
	
	for (let i = 0; i < newsfilter.length; i++) {
	
		let child = newsfilter[i].children;
		new Waypoint({
			element: newsfilter[i],
			handler: function() {
				for (let c = 0; c < child.length; c++) {
					anime({
						opacity: 1,
						targets: child[c],
						translateY: ['30px', '0'],
						duration: 500,
						easing: 'easeInOutQuad',
						delay: 100 * c
					});
					this.destroy();
				}
			},
			offset: 'bottom-in-view'
		});
	}
	
	const scale = document.querySelectorAll('.promotion-item__pct img,' +
		'.landing-list-item__pct img,' +
		'.contacts-banner img,' +
		'.page-banner img');
	
	for (let i = 0; i < scale.length; i++) {
		new Waypoint({
			element: scale[i],
			handler: function() {
				anime({
					opacity: 1,
					targets: this.element,
					scale: ['1', '1.1'],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: '70%'
		});
	}
	
	
	const fadeDown = document.querySelectorAll('.fadeDown');
	
	for (let i = 0; i < fadeDown.length; i++) {
		new Waypoint({
			element: fadeDown[i],
			handler: function() {
				anime({
					opacity: 1,
					targets: this.element,
					translateY: ['-50px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: 'bottom-in-view'
		});
	}
	
	const fadeToLeft = document.querySelectorAll('.fadeToLeft');
	
	for (let i = 0; i < fadeToLeft.length; i++) {
		new Waypoint({
			element: fadeToLeft[i],
			handler: function() {
				anime({
					opacity: 1,
					targets: this.element,
					translateX: ['100px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: 'bottom-in-view'
		});
	}
	
	const fadeToRight = document.querySelectorAll('.fadeToRight');
	
	for (let i = 0; i < fadeToRight.length; i++) {
		new Waypoint({
			element: fadeToRight[i],
			handler: function() {
				anime({
					opacity: 1,
					targets: this.element,
					translateX: ['-100px', '0'],
					duration: 500,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: 'bottom-in-view'
		});
	}
	
	const fadeScale = document.querySelectorAll('.fadeScale');
	
	for (let i = 0; i < fadeScale.length; i++) {
		new Waypoint({
			element: fadeScale[i],
			handler: function() {
				anime({
					opacity: 1,
					targets: this.element,
					scale: ['0.9', '1'],
					duration: 1000,
					easing: 'easeInOutQuad',
				});
				this.destroy();
			},
			offset: 'bottom-in-view'
		});
	}
	
	const childCascadeFade = document.querySelectorAll('.childCascadeFade');
	
	for (let i = 0; i < childCascadeFade.length; i++) {
		let child = childCascadeFade[i].children;
		new Waypoint({
			element: childCascadeFade[i],
			handler: function() {
				for (let c = 0; c < child.length; c++) {
					anime({
						opacity: 1,
						targets: child[c],
						duration: 500,
						easing: 'easeInOutQuad',
						delay: 200 * c
					});
					this.destroy();
				}
			},
			offset: childCascadeFadeUp[i].offsetHeight * 2
		});
	}
	

	let marquee = new Swiper('.marquee', {
		spaceBetween: 0,
		centeredSlides: true,
		speed: 6000,
		autoplay: {
			delay: 1,
		},
		loop: true,
		slidesPerView:'auto',
		allowTouchMove: false,
		disableOnInteraction: true
	});

	mediaCarousel = new Swiper('.media-carousel', {
		slidesPerView: 3,
		spaceBetween: 16,
		scrollbar: {
			el: ".media-count .progress",
		},
		// Responsive breakpoints
		breakpoints: {
			1: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 3,
			}
		},
		on: {
			init: function (e) {
				if (e.slides.length > e.params.slidesPerView) {
					$( '.media-carousel-nav' ).addClass( 'visible' );
				} else {
					$( '.media-carousel-nav' ).removeClass( 'visible' );
				}
			},
			resize: function (e) {
				if (e.slides.length > e.params.slidesPerView) {
					$( '.media-carousel-nav' ).addClass( 'visible' );
				} else {
					$( '.media-carousel-nav' ).removeClass( 'visible' );
				}
			}
		},
	});

	if (getCookie('cookies') !== '1') {
		$( '.cookies' ).addClass( 'active' );
	}

	$( '.header-search input' ).each( function() {
		if ( $( this ).val().length > 0) {
			$( this ).addClass( 'filled' );
		} else {
			$( this ).removeClass( 'filled' );
		}
	});

	if ($( '.anchors' )) {
		let anchor = 0;
		$( '.text-content h1,.text-content h2,.text-content h3' ).each( function() {
			anchor++;
			$( this ).attr( 'id', 'anchor-' + anchor )
			let listItem = '<li data-anchor="anchor-' + anchor + '">' + $( this ).text() + '</li>';
			$( '.anchors ol' ).html( $( '.anchors ol' ).html() + listItem );
		});
		$( '.anchors li' ).first().addClass( 'active' );
	}

	$( '[data-fancybox]' ).fancybox({
		'touch': false
	});

	$(window).on('mousemove', handleMouseMove);
	$(window).on('scroll', handleMouseMove);
	updateElementPosition();

	if ( window.matchMedia( "(max-width: 1300px)" ).matches ) {
		selectricHeight = 166;
	} else {
		selectricHeight = 600;
	}


	select.on('selectric-init', function(element) {
		let container = $( element.target ).closest('.selectric-wrapper').find( '.selectric-scroll' );
		if ( container ) {
			const ps = new PerfectScrollbar(container[0]);
		}
	});

	$( 'select' ).on('selectric-open', function(element) {
		console.log(element.target);
		let container = $( element.target ).closest('.selectric-wrapper').find( '.selectric-scroll' );
		if ( container ) {
			const ps = new PerfectScrollbar(container[0]);
		}
	});

	$( 'select' ).selectric({
		disableOnMobile: false,
		nativeOnMobile: false,
		maxHeight: selectricHeight,
	});

	$( '.btn' ).each( function() {
		$( this ).html( '<span>' + $( this ).text() + '</span>' );
		$( this ).find( 'span' ).attr( 'data-text', $( this ).text());

		if ($( this ).hasClass( 'btn--show-more' )) {
			$( this ).append( '<span class="sma"></span>' );
		}
	});

	$( '.footer-grid--bottom a' ).each( function() {
		$( this ).html( '<span>' + $( this ).text() + '</span>' );
		$( this ).find( 'span' ).attr( 'data-text', $( this ).text());
	});

	$( '.footer-column ul a' ).each( function() {
		$( this ).html( '<span>' + $( this ).text() + '</span>' );
		$( this ).find( 'span' ).attr( 'data-text', $( this ).text());
	});

	$( '.header-nav ul a' ).each( function() {
		$( this ).html( '<span>' + $( this ).text() + '</span>' );
		$( this ).find( 'span' ).attr( 'data-text', $( this ).text());
	});

	$( '.footer-nav ul a' ).each( function() {
		$( this ).html( '<span>' + $( this ).text() + '</span>' );
		$( this ).find( 'span' ).attr( 'data-text', $( this ).text());
	});

	$( '.share-item__nn' ).each( function() {
		$( this ).html( '<span>' + $( this ).text() + '</span>' );
		$( this ).find( 'span' ).attr( 'data-text', $( this ).text());
	});

	$( '.share-item__label' ).each( function() {
		$( this ).html( '<span>' + $( this ).text() + '</span>' );
		$( this ).find( 'span' ).attr( 'data-text', $( this ).text());
	});
});

$( 'body' ).on( 'click', 'a[href*="#"]:not([data-fancybox]), a[href^="#"]:not([data-fancybox])', function( e ) {
	e.preventDefault();
	let el = $( $( this ).attr( 'href' ) );
	console.log(el.length);
	if (el.length > 0) {
		let scrollTop = el.offset().top - $( 'header' ).outerHeight() + 2;
		$('html, body').animate({
			scrollTop: scrollTop
		}, 1000);
	}
});

$( 'body' ).on( 'click', '[data-anchor]', function() {
	let scrollTop = $( '.text-content #' + $( this ).data( 'anchor' ) ).offset().top - $( 'header' ).outerHeight() - 40;
	$('html, body').animate({
		scrollTop: scrollTop
	}, 500);
});

$( 'body' ).on( 'click', '.events-filter-item', function() {
	setTimeout( function () {

		$( '.event-item__more .btn' ).each( function() {
			$( this ).html( '<span>' + $( this ).text() + '</span>' );
			$( this ).find( 'span' ).attr( 'data-text', $( this ).text());

			if ($( this ).hasClass( 'btn--show-more' )) {
				$( this ).append( '<span class="sma"></span>' );
			}
		});
	}, 1000 );
});

$( 'body' ).on( 'click', '.btn--show-more, .news-filter-item, .events-filter-item', function() {
	setTimeout( function () {
		allAnimations();
	}, 1000 );
});



$( 'body' ).on( 'click', '.seo-text__toggle', function() {
	$( this ).toggleClass( 'active' );
	$( '.seo-text__content' ).toggleClass( 'active' );

	if ( $( this ).hasClass( 'active' ) ) {
		$( this ).find('span').text( $( this ).data( 'less' )).attr( 'data-text', $( this ).data( 'less' ) );
	} else {
		$( this ).find('span').text( $( this ).data( 'more' )).attr( 'data-text', $( this ).data( 'more' ) );
	}
});

$( 'body' ).on( 'click', '.cookies .btn', function() {
	$( '.cookies' ).fadeOut(300);
	setCookie('cookies', '1', 7);
});


$( 'body' ).on('mousemove', '[data-hover]', function() {
	$('.follow-cursor').addClass('active').find('.' + $(this).data('hover')).show().siblings().hide()
});

$( 'body' ).on('mouseleave', '[data-hover]', function() {
	$('.follow-cursor').removeClass('active');
});

$( 'body' ).on('click', '.header-search-toggle', function() {
	$( 'header .header-search' ).fadeToggle(300);
});

$( 'body' ).on('click', '.next-section', function() {
	let scrollTop = $( this ).closest( 'section' ).next().offset().top;
	$('html, body').animate({
		scrollTop: scrollTop
	}, 500);
});

$( 'body' ).on('click', '.header-nav-toggle', function() {
	$( '.mobile-nav' ).toggleClass( 'active' );
	if ( $( this ).hasClass( 'active' ) ) {
		enableScroll();
	} else {
		disableScroll();
	}
	$( this ).toggleClass( 'active' );
});

$( 'body' ).on('click', '.faq-item__q', function() {
	$( this ).toggleClass( 'active' ).next().slideToggle();
});

$( 'body' ).on('click', '.section--bio', function() {
	$( this ).hide().removeClass( 'section--padding' );
	$('html, body').stop().animate({
		scrollTop: 0
	}, 1000);
});

$( 'body' ).on('click', '[data-hover="read-bio"]', function() {
	$( '.section--bio' ).show().addClass( 'section--padding' );
	let top = $( '.section--bio' ).offset().top;
	$('html, body').stop().animate({
		scrollTop: top
	}, 1000);
});

$( 'body' ).on('click', '.header-search__clear', function() {
	$( this ).closest( 'form' ).find( 'input' ).val('').removeClass( 'filled' );
});

$( 'body' ).on('change keyup', '.header-search input', function() {
	if ( $( this ).val().length > 0) {
		$( this ).addClass( 'filled' );
	} else {
		$( this ).removeClass( 'filled' );
	}
});

$( 'body' ).on('click', '.news-filter-item, .events-filter-item', function() {
	setTimeout(function () {
	}, 300);
});

$( 'body' ).on('click', '[data-target]', function() {
	setTimeout(function () {
	}, 300)
	$( this ).addClass( 'active' ).siblings().removeClass( 'active' )
	if ( $( this ).data( 'target' ) === 'all' ) {
		$( '[data-tab]' ).slideDown(300);
	} else {
		$( '[data-tab="' + $( this ).data( 'target' ) + '"]' ).slideDown(300).siblings( '[data-tab]' ).slideUp(300);
	}
});

$( 'body' ).on('click', '.media-carousel-arrow--prev', function() {
	mediaCarousel.slidePrev();
});

$( 'body' ).on('click', '.media-carousel-arrow--next', function() {
	mediaCarousel.slideNext();
});

$( 'body' ).on('click', '.anchors li', function() {
	$( this ).addClass( 'active' ).siblings().removeClass( 'active' )
});

$(window).on('scroll', function() {
	var scrollTop = $(window).scrollTop();
	var documentHeight = $(document).height();
	var windowHeight = $(window).height();
	var scrollPercent = (scrollTop / (documentHeight - windowHeight)) * 100;
	$('#progressbar').css('width', scrollPercent + '%');
});

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
	e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
	if (keys[e.keyCode]) {
		preventDefault(e);
		return false;
	}
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
	window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
		get: function () { supportsPassive = true; }
	}));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
	window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
	window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
	window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
	window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
	window.removeEventListener('DOMMouseScroll', preventDefault, false);
	window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
	window.removeEventListener('touchmove', preventDefault, wheelOpt);
	window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

function setCookie(name,value,days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for( var i=0; i < ca.length; i++ ) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1,c.length);
		if ( c.indexOf(nameEQ) == 0 ) return c.substring(nameEQ.length,c.length);
	}
	return null;
}
function eraseCookie(name) {
	document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function addClassOnScroll(selector, className) {
	function checkVisibility() {
		$(selector).each(function() {
			var blockPosition = $(this).offset().top;
			var windowHeight = $(window).height();
			var scrollPosition = $(window).scrollTop();

			if (scrollPosition + windowHeight > blockPosition) {
				$(this).addClass(className);
			}
		});
	}

	checkVisibility();

	$(window).on('scroll', function() {
		checkVisibility();
	});
}
